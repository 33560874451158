import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "When do people buy candy? 🍬",
  "description": "Candy is delicious. When do people buy it most? Visualize the data in a fun way",
  "date": "2018-12-12T08:00:00.000Z",
  "published": "2018-12-12T08:00:00.000Z",
  "image": "./candy.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <lite-youtube {...{
      "videoid": "Dn_kHCFTUP4",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`Candy is delicious. When do people buy it most? Visualize the data in a fun way`}</p>
    <strong>Dataset: </strong>
    <a href={`./statistic_id947149_retail-sales-of-candy-in-the-united-states-in-2018-by-week.xlsx`}>
  Download dataset 🗳{" "}
    </a>
    <h2 {...{
      "id": "my-solution-"
    }}>{`My solution 👇`}</h2>
    <iframe {...{
      "src": "https://codesandbox.io/embed/ov0lzxmplz",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <h2 {...{
      "id": "how-it-works-️"
    }}>{`How it works ⚙️`}</h2>
    <p>{`Did you know Americans buy `}<inlineCode parentName="p">{`Eight hundred million dollars`}</inlineCode>{` worth of candy on Easter? That's crazy. Absolutely bonkers. Even the normal baseline of `}<inlineCode parentName="p">{`$300,000,000`}</inlineCode>{`/week throughout the year is just staggering. 🍭`}</p>
    <p>{`What better way to visualize it than candy falling from the sky into the shape of a bar chart?`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/z9bNPZL.gif",
        "alt": null
      }}></img></p>
    <p>{`The basic idea behind that visualization goes like this:`}</p>
    <ol>
      <li parentName="ol">{`Load and parse data`}</li>
      <li parentName="ol">{`Scale for horizontal position`}</li>
      <li parentName="ol">{`Scale for vertical height`}</li>
      <li parentName="ol">{`Render each bar in a loop`}</li>
      <li parentName="ol">{`Divide height by `}<inlineCode parentName="li">{`12`}</inlineCode></li>
      <li parentName="ol">{`Render that many emojis`}</li>
      <li parentName="ol">{`Create a custom tween transition to independently animate horizontal and vertical positionioning in a declarative and visually pleasing way`}</li>
    </ol>
    <p>{`😛`}</p>
    <h2 {...{
      "id": "the-basics"
    }}>{`The basics`}</h2>
    <p>{`Let's start with the basics and get them out of the way. Bottom up in the Codesandbox above.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const FallingCandy = ({ data, x = 0, y = 0, width = 600, height = 600 }) => {
  const xScale = d3
    .scalePoint()
    .domain(data.map((d) => d.week))
    .range([0, width])
  const yScale = d3
    .scaleLinear()
    .domain([250, d3.max(data, (d) => d.sales)])
    .range([height, 0])

  return (
    <g transform={\`translate(\${x}, \${y})\`}>
      {data.map((d) => (
        <CandyJar
          x={xScale(d.week)}
          y={height}
          height={height - yScale(d.sales)}
          delay={d.week * Math.random() * 100}
          type={d.special}
          key={d.week}
        />
      ))}
      <BottomAxis scale={xScale} x={0} y={height} />
      <LeftAxis scale={yScale} x={0} y={0} />
    </g>
  )
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`<FallingCandy>`}</inlineCode>{` component takes data, positioning, and sizing props. Creates two scales: A point scale for horizontal positioning of each column, a vertical scale for heights.`}</p>
    <p>{`Render a grouping element to position everything, walk through the data and render a `}<inlineCode parentName="p">{`<CandyJar>`}</inlineCode>{` component for each entry. Candy jars need coordinates, a height, some delay for staggered animations, and a type.`}</p>
    <p>{`Type tells them which emoji to render. Makes it so we can have special harts on Valentine's day, bunnies on Easter, jack-o-lanterns on Halloween, and Christmas trees on Christmas.`}</p>
    <p>{`I know this works because when my girlfriend saw it this morning she was like `}<em parentName="p">{`"Whaaat why so much candy on Easter?"`}</em>{`. Didn't even have to tell her what the emojis mean 💪`}</p>
    <p>{`We'll talk about the animation staggering later. I'll explain why it has to be random as well.`}</p>
    <h2 {...{
      "id": "the-axes"
    }}>{`The axes`}</h2>
    <p>{`Using our standard approach for axes: use `}<a parentName="p" {...{
        "href": "https://d3blackbox.com"
      }}>{`d3blackbox`}</a>{` to render an anchor element, then take over with D3 and use an axis generator.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const BottomAxis = d3blackbox((anchor, props) => {
  const scale = props.scale
  scale.domain(scale.domain().filter((_, i) => i % 5 === 0))

  const axis = d3
    .axisBottom()
    .scale(props.scale)
    .tickFormat((d) => \`wk \${d}\`)
  d3.select(anchor.current).call(axis)
})

const LeftAxis = d3blackbox((anchor, props) => {
  const axis = d3
    .axisLeft()
    .scale(props.scale)
    .tickFormat((d) => \`$\${d} million\`)
  d3.select(anchor.current).call(axis)
})
`}</code></pre>
    <p>{`We have to filter the scale's domain for `}<inlineCode parentName="p">{`<BottomAxis>`}</inlineCode>{` because point scales are ordinal. That means there's no generalized way to interpolate values in between other values, so the axis renders everything.`}</p>
    <p>{`That looks terrible. Instead, we render every 5th tick.`}</p>
    <p>{`Both axes get a custom `}<inlineCode parentName="p">{`tickFormat`}</inlineCode>{` so they're easier to read.`}</p>
    <h2 {...{
      "id": "the-candyjar"
    }}>{`The `}{`<`}{`CandyJar>`}</h2>
    <p>{`Candy jars are just columns of emojis. There's not much logic here.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const CandyJar = ({ x, y, height, delay, type }) =>
  d3
    .range(height / 12)
    .map((i) => (
      <Candy
        x={x}
        y={y - i * 12}
        type={type}
        delay={delay + i * Math.random() * 100}
        key={i}
      />
    ))
`}</code></pre>
    <p>{`Yes, we could have done this in the main `}<inlineCode parentName="p">{`<FallingCandy>`}</inlineCode>{` component. Code feels cleaner this way.`}</p>
    <p>{`Create a counting array from zero to `}<inlineCode parentName="p">{`height/12`}</inlineCode>{`, the number of emojis we need, walk through the array and render `}<inlineCode parentName="p">{`<Candy>`}</inlineCode>{` components for each entry. At this point we add some more random delay. I'll tell you why in a bit.`}</p>
    <h2 {...{
      "id": "the-animated-candy-component"
    }}>{`The animated `}{`<`}{`Candy> component`}</h2>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/z9bNPZL.gif",
        "alt": null
      }}></img></p>
    <p>{`All that animation happens in the Candy component. Parent components are blissfully unaware and other than passing a `}<inlineCode parentName="p">{`delay`}</inlineCode>{` prop never have to worry about the details of rendering and animation.`}</p>
    <p>{`That's the beauty of declarative code. 👌`}</p>
    <p>{`Our plan is based on my `}<a parentName="p" {...{
        "href": "https://swizec.com/blog/declarative-d3-transitions-react/swizec/8323"
      }}>{`Declarative D3 transitions with React 16.3+`}</a>{` approach:`}</p>
    <ol>
      <li parentName="ol">{`Move coordinates into state`}</li>
      <li parentName="ol">{`Render emoji from state`}</li>
      <li parentName="ol">{`Run transition on `}<inlineCode parentName="li">{`componentDidMount`}</inlineCode></li>
      <li parentName="ol">{`Update state when transition ends`}</li>
    </ol>
    <p>{`We use component state as a sort of staging area for transitionable props. D3 helps us with what it does best - transitions - and React almost always knows what's going on so it doesn't get confused.`}</p>
    <p>{`Have had issues in the past with manipulating the DOM and React freaking out at me.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class Candy extends React.Component {
  state = {
    x: Math.random() * 600,
    y: Math.random() * -50,
  }
  candyRef = React.createRef()

  componentDidMount() {
    const { delay } = this.props

    const node = d3.select(this.candyRef.current)

    node
      .transition()
      .duration(1500)
      .delay(delay)
      .ease(d3.easeLinear)
      .attrTween("y", candyYTween(this.state.y, this.props.y))
      .attr("x", this.props.x)
      .on("end", () => this.setState({ y: this.props.y }))
  }

  get emoji() {
    // return emoji based on this.props.type
  }

  render() {
    const { x, y } = this.state

    return (
      <text x={x} y={y} style={{ fontSize: "12px" }} ref={this.candyRef}>
        {this.emoji}
      </text>
    )
  }
}
`}</code></pre>
    <p>{`We initate the `}<inlineCode parentName="p">{`<Candy>`}</inlineCode>{` component in a random location off screen. Too high up to be seen, somewhere on the visualization horizontally. Doesn't matter where.`}</p>
    <p>{`I'll show you why random soon.`}</p>
    <p>{`We create a ref as well. D3 will need that to get access to the DOM node.`}</p>
    <p>{`Then we have `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{` which is where the transition happens.`}</p>
    <h3 {...{
      "id": "separate-yet-parallel-transitions-for-each-axis"
    }}>{`Separate, yet parallel, transitions for each axis`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`  componentDidMount() {
    const { delay } = this.props

    const node = d3.select(this.candyRef.current)

    node
      .transition()
      .duration(1500)
      .delay(delay)
      .ease(d3.easeLinear)
      .attrTween('y', candyYTween(this.state.y, this.props.y))
      .attr('x', this.props.x)
      .on('end', () => this.setState({ y: this.props.y }))
  }
`}</code></pre>
    <p>{`Key logic here is that we `}<inlineCode parentName="p">{`d3.select()`}</inlineCode>{` the candy node, start a transition on it, define a duration, pass the delay from our props, `}<em parentName="p">{`disable`}</em>{` easing functions, and specify what's transitioning.`}</p>
    <p>{`The tricky bit was figuring out how to run two different transitions in parallel.`}</p>
    <p>{`D3 doesn't do concurrent transitions, you see. You have to run `}<strong parentName="p">{`a`}</strong>{` transition, then the next one. Or you have to cancel the first transition and start a new one.`}</p>
    <p>{`Of course you `}<em parentName="p">{`can`}</em>{` run concurrent transitions on multiple attributes. But only if they're both the same transition.`}</p>
    <p>{`In our case we wanted to have candy bounce vertically and fly linearly in the horizontal direction. This was tricky.`}</p>
    <p>{`I mean I guess it's okay with a bounce in both directions? 🧐`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/L00eWnG.gif",
        "alt": null
      }}></img></p>
    <p>{`No that's weird.`}</p>
    <h3 {...{
      "id": "you-can-do-it-with-a-tween"
    }}>{`You can do it with a tween`}</h3>
    <p>{`First you have to understand some basics of how transitions and easing functions work.`}</p>
    <p>{`They're based on interpolators. An interpolator is a function that calculates in-between values between a start and end value based on a `}<inlineCode parentName="p">{`t`}</inlineCode>{` argument. When `}<inlineCode parentName="p">{`t=0`}</inlineCode>{`, you get the initial value. When `}<inlineCode parentName="p">{`t=1`}</inlineCode>{` you get the end value.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const interpolate = d3.interpolate(0, 100)

interpolate(0) // 0
interpolate(0.5) // 50
interpolate(1) // 1
`}</code></pre>
    <p>{`Something like that in a nutshell. D3 supports much more complex interpolations than that, but numbers are all we need right now.`}</p>
    <p>{`Easing functions manipulate how that `}<inlineCode parentName="p">{`t`}</inlineCode>{` parameter behaves. Does it go from `}<inlineCode parentName="p">{`0`}</inlineCode>{` to `}<inlineCode parentName="p">{`1`}</inlineCode>{` linearly? Does it bounce around? Does it accelerate and slow down?`}</p>
    <p>{`When you start a transition with `}<inlineCode parentName="p">{`easeLinear`}</inlineCode>{` and `}<inlineCode parentName="p">{`attr('x', this.props.x)`}</inlineCode>{` you are essentially creating an interpolator from the current value of `}<inlineCode parentName="p">{`x`}</inlineCode>{` to your desired value, and the `}<inlineCode parentName="p">{`t`}</inlineCode>{` parameter changes by an equal amount on every tick of the transition.`}</p>
    <p>{`If you have `}<inlineCode parentName="p">{`1500`}</inlineCode>{` milliseconds to finish the transition (your duration), that's 90 frames at 60fps. Means your `}<inlineCode parentName="p">{`t`}</inlineCode>{` adds 0.01 on every tick of the animation.`}</p>
    <p>{`We can use that to create a custom tween for the vertical coordinate, `}<inlineCode parentName="p">{`y`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function candyYTween(oldY, newY) {
  const interpolator = d3.interpolate(oldY, newY)
  return function () {
    return function (t) {
      return interpolator(d3.easeBounceOut(t))
    }
  }
}
`}</code></pre>
    <p><inlineCode parentName="p">{`candyYTween`}</inlineCode>{` takes the initial and new coordinates, creates an interpolator, and returns a function. This function returns a parametrized function that drives our transition. For every `}<inlineCode parentName="p">{`t`}</inlineCode>{` we return the value of our `}<inlineCode parentName="p">{`interpolator`}</inlineCode>{` after passing it through the `}<inlineCode parentName="p">{`easeBounceOut`}</inlineCode>{` easing function.`}</p>
    <p>{`We're basically taking a linear parameter, turning it into a bouncy paramater, and passing `}<em parentName="p">{`that`}</em>{` into our interpolator. This creates a bouncy effect without affecting the `}<inlineCode parentName="p">{`x`}</inlineCode>{` coordinate in the other transition.`}</p>
    <p>{`I don't know why we need the double function wrap, but it didn't work otherwise.`}</p>
    <h2 {...{
      "id": "so-why-all-the-randomness"
    }}>{`So why all the randomness?`}</h2>
    <p>{`Randomness makes our visualization look better. More natural.`}</p>
    <p>{`Here's what it looks like without any `}<inlineCode parentName="p">{`Math.random()`}</inlineCode></p>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`Here's why adding randomness to your animations matters 👇`}<br parentName="blockquote"></br><br parentName="blockquote"></br>{`This chart of candy buying habits in the US is not random at all. Delay based purely on array index. `}</blockquote>{`
        `}<div parentName="div" {...{
          "className": "media"
        }}><img parentName="div" {...{
            "src": "https://pbs.twimg.com/ext_tw_video_thumb/1073284491550978048/pu/img/pPEeutiSoWpP7TqS.jpg",
            "width": "100%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img></div>{`
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1073285327282876416"
          }}>{`6:35:37 PM – 12/13/2018`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=1073285327282876416",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`17`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1073285327282876416",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`1`}</a></div>{`
    `}</div></div>
    <p>{`Randomness on the CandyJar level.`}</p>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`Here we add randomness to the column delay. `}</blockquote>{`
        `}<div parentName="div" {...{
          "className": "media"
        }}><img parentName="div" {...{
            "src": "https://pbs.twimg.com/ext_tw_video_thumb/1073284623788982272/pu/img/l6lyRXPUwPWZiuEC.jpg",
            "width": "100%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img></div>{`
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1073285329237401600"
          }}>{`6:35:37 PM – 12/13/2018`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=1073285329237401600",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`2`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1073285329237401600",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`1`}</a></div>{`
    `}</div></div>
    <p>{`Randomness on the CandyJar `}<em parentName="p">{`and`}</em>{` Candy level.`}</p>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`Adding a random delay to each individual emoji makes it even better 🧐 `}</blockquote>{`
        `}<div parentName="div" {...{
          "className": "media"
        }}><img parentName="div" {...{
            "src": "https://pbs.twimg.com/ext_tw_video_thumb/1073284941725589504/pu/img/xmS62BYLaKhEOAEJ.jpg",
            "width": "100%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img></div>{`
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1073285332555194369"
          }}>{`6:35:38 PM – 12/13/2018`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=1073285332555194369",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`3`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1073285332555194369",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`1`}</a></div>{`
    `}</div></div>
    <p>{`Randomness in the start position as well.`}</p>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`And when you add a random start point as well, that's when you unlock true beauty 👌`}<br parentName="blockquote"></br><br parentName="blockquote"></br><a parentName="blockquote" {...{
            "href": "https://twitter.com/hashtag/ReactVizHoliday"
          }}>{`#ReactVizHoliday`}</a>{` Day 9 was fun like that.`}<br parentName="blockquote"></br>{`Check it out here 👉  `}</blockquote>{`
        `}<div parentName="div" {...{
          "className": "media"
        }}><img parentName="div" {...{
            "src": "https://pbs.twimg.com/ext_tw_video_thumb/1073285169287581697/pu/img/occ7aOd02YP8fIzi.jpg",
            "width": "100%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img></div>{`
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1073285334291501056"
          }}>{`6:35:39 PM – 12/13/2018`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=1073285334291501056",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`13`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1073285334291501056",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`4`}</a></div>{`
    `}</div></div>
    <p>{`You decide which looks best ✌️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      